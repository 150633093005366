/* Add CSS */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Add global CSS here */
/* Add CSS stylesheet */

* {
  font-family:
    'Inter',
    var,
    ui-sans-serif,
    system-ui,
    sans-serif,
    Apple Color Emoji,
    Segoe UI Emoji,
    Segoe UI Symbol,
    Noto Color Emoji;
}

html {
  height: 99%;
}

body,
#root {
  height: 100%;
}
