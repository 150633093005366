.menu_button:first-child {
  margin-top: 0;
}

.menu_button {
  height: 40px;
  width: 100%;
  text-align: center;
  line-height: 40px;
}
